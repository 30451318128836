export const FETCH_ALL ='FETCH_ALL';
export const PLACE_ORDER = 'PLACE_ORDER';
export const UPDATE ='UPDATE';
export const DELETE = 'DELETE';
export const AUTH='AUTH';
export const LOGOUT='LOGOUT';
export const IS_LOADING='IS_LOADING';
export const CLIENT_MSG='CLIENT_MSG';
export const ADMIN='ADMIN';
export const CLUB_DIRECTORS='CLUB_DIRECTORS';
export const ACTIVITY_TYPE='ACTIVITY_TYPE';
export const ACTIVITY_SUBTYPE='ACTIVITY_SUBTYPE';
export const ACTIVITY_CATEGORY='ACTIVITY_CATEGORY';
export const ACTIVITY_PLACEHOLDER='ACTIVITY_PLACEHOLDER';
export const REPORTED_ACTIVITY='REPORTED_ACTIVITY';
export const ADMIN_REPORTS='ADMIN_REPORTS';
export const ADMIN_PDF='ADMIN_PDF';
export const ADMIN_FORM='ADMIN_FORM';
export const ADMIN_POINTS="ADMIN_POINTS";
export const UPDATE_REPORT="UPDATE_REPORT";
export const REPORTED_NEWS="REPORTED_NEWS";
export const MEMBER_PROFILE="MEMBER_PROFILE";
export const UPDATE_MEMBER_PROFILE="UPDATE_MEMBER_PROFILE";
export const CLUB_MEMBERS="CLUB_MEMBERS";
export const ALL_MEMBERS='ALL_MEMBERS';
export const ZONE_DATA="ZONE_DATA";
export const REGION_DATA="REGION_DATA";
export const STATEMENT="STATEMENT";
export const ACTIVITY_STATS="ACTIVITY_STATS";
export const EVENTS="EVENTS";
export const TOPCLUBS="TOPCLUBS";
export const SLIDER_IMAGES="SLIDER_IMAGES";
export const GALLARY_IMAGES="GALLARY_IMAGES";
export const TOP_NEWS="TOP_NEWS";
export const MEMBER_DIRECTORY="MEMBER_DIRECTORY";
export const BUSINESS_DIRECTORY="BUSINESS_DIRECTORY";
export const ALL_CLUBS="ALL_CLUBS";
export const SELECT_CLUBS="SELECT_CLUBS";
export const SELECT_ALL_CLUBS="SELECT_ALL_CLUBS";
export const TITLES="TITLES";
export const SELECT_TITLES="SELECT_TITLES";
export const SELECT_ALL_TITLES="SELECT_ALL_TITLES";
export const DISTRICT_DATA="DISTRICT_DATA";
export const RESOURCE_DATA="RESOURCE_DATA";
export const CLUBS_REPORTING="CLUBS_REPORTING";
export const SELECTED_MONTH="SELECTED_MONTH";
export const DELETE_ACTIVITY="DELETE_ACTIVITY";
export const DELETE_NEWS="DELETE_NEWS";
export const ADMIN_REPORTS_LOADING="ADMIN_REPORTS_LOADING";
export const ACTIVITY_LOADING="ACTIVITY_LOADING";
export const CLUB_LIST="CLUB_LIST";
export const ACTIVITY_FILTER="ACTIVITY_FILTER";
export const RESET_FILTER="RESET_FILTER";
