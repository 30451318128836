import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    checkItems: {
        border: '1px solid #000',
        '& .group': {
            border: '1px solid #000',
            
        }
    }
}))